import React, { useState } from "react"
import { Link } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import Layout from "../components/layout"

const Contacts = () => {
  const [contactForm, setContactForm] = useState({
    email: "",
    title: "",
    body: "",
  })

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [formSent, setFormSent] = useState(false)
  const [formError, setFormError] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("contact")

    const data = { ...contactForm, token }

    fetch(`https://app.time2client.com/api/new-contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (!resultData.received) {
          setFormError("Server error")
        }
        setFormSent(true)
      })
      .catch(error => {
        console.error(error)
        setFormSent(true)
        setFormError(error)
      })
  }

  return (
    <Layout>
      <main role="main" className="bg-white">
        <div style={{ backgroundColor: "#e7e7e7" }} className="relative">
          <img
            src="/images/contacts.jpeg"
            className="db center"
            style={{ width: "64rem" }}
            alt=""
          />
        </div>

        <div className="mw7 center pa4">
          {formSent ? (
            formError ? (
              <div className="pv6 tc">
                <h3 className="f2 lato fw4">Errore nell'invio del form</h3>
                <p className="red">{JSON.stringify(formError)}</p>
              </div>
            ) : (
              <div className="pv6 tc">
                <h3 className="f2 lato fw4">Grazie per il vostro messaggio.</h3>
                <p>In caso di necessità verrà ricontattato dal nostro team.</p>
              </div>
            )
          ) : (
            <>
              <h2 className="f2 lato fw4">Contatti</h2>
              <form onSubmit={handleSubmit} method="post">
                <div className="pv2 measure">
                  <label htmlFor="form-email" className="f6 b db mb2 lato">
                    Email
                  </label>
                  <input
                    className="input-reset ba pa2 mb2 lato db w-100 b--black-20"
                    type="email"
                    id="form-email"
                    value={contactForm.email}
                    onChange={e => {
                      setContactForm({ ...contactForm, email: e.target.value })
                    }}
                  />
                </div>
                <div className="pv2 measure">
                  <label htmlFor="form-title" className="f6 b db mb2 lato">
                    Titolo
                  </label>
                  <input
                    className="input-reset ba pa2 mb2 lato db w-100 b--black-20"
                    type="text"
                    id="form-title"
                    value={contactForm.title}
                    onChange={e => {
                      setContactForm({ ...contactForm, title: e.target.value })
                    }}
                  />
                </div>
                <div className="pv2 measure">
                  <label htmlFor="form-body" className="f6 b db mb2 lato">
                    Contenuto
                  </label>
                  <textarea
                    className="input-reset ba pa2 mb2 lato db w-100 b--black-20"
                    rows="10"
                    id="form-body"
                    value={contactForm.body}
                    onChange={e => {
                      setContactForm({ ...contactForm, body: e.target.value })
                    }}
                  ></textarea>
                </div>
                <div className="bg-light-gray pa3 f6">
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a href="https://policies.google.com/privacy">
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a href="https://policies.google.com/terms">
                    Terms of Service
                  </a>{" "}
                  apply.
                </div>
                <div className="flex items-center mt3">
                  <span className="link-animate">
                    <Link
                      className="mr3 min-w-8 pointer black-50 hover-black pa3 dib"
                      to="/"
                    >
                      indietro
                    </Link>
                  </span>
                  <button
                    className="dib ba br2 min-w-8 pointer b--sec-color hover-white white bg-sec-color hover-bg-prim-color hover-b--prim-color pa3 shadow-5"
                    type="submit"
                  >
                    <span className="f5 lato">Invia messaggio</span>
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </main>
    </Layout>
  )
}

export default Contacts
